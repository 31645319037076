/** @jsx jsx */

import { jsx, Box, Heading } from "theme-ui"
import { Link } from "../../../components/nav"
import {
  ContentBlock,
  HeaderSpacing,
  MapImageGlobal,
} from "../../../components/page"
import { Trans } from "react-i18next"
import { Fragment } from "react"

export default () => {
  return (
    <Fragment>
      <MapImageGlobal />
      <HeaderSpacing />
      <ContentBlock>
        <Box sx={{ width: ["100%", "100%", "50%"] }}>
          <Heading variant="headings.1" sx={{ mb: 3 }}>
            <Trans>Welcome to the Progressive International.</Trans>
          </Heading>
          <Heading as="h3" variant="headings.3" sx={{ mb: 3 }}>
            <Trans>
              We will review your application and confirm your membership as
              soon as possible.
            </Trans>
          </Heading>
          <Heading as="h3" variant="headings.3" sx={{ mb: 3 }}>
            <Trans>
              All applications are evaluated on the basis of the Progressive
              International's core principles.
            </Trans>
          </Heading>
          <Heading as="h3" variant="headings.3" sx={{ mb: 3 }}>
            <Trans>
              Invite your friends, colleagues or members to join as individuals{" "}
              <Link to="/join">here</Link>.
            </Trans>
          </Heading>
        </Box>
      </ContentBlock>
    </Fragment>
  )
}
